<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>退换货管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/orderExchange' }">换货列表</el-breadcrumb-item>
        <el-breadcrumb-item>批量换货</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="shebei">商品信息</div>
        <div class="content_li-padding">

          <el-form ref="ruleForm" :model="form" :rules="rules">
            <el-table
                border
                :data="form.tableList"
                v-loading="tableLoading"
                style="width: 100%"
                :header-cell-style="this.utils.getRowClass"
            >
              <el-table-column align="center" prop="orderNumber" :show-overflow-tooltip="true" label="订单编号"
                               :min-width="150"></el-table-column>
              <el-table-column align="center" prop="totalCount" label="商品数量" v-if="false"></el-table-column>
              <el-table-column align="center" prop="userTakeName" label="收件人"></el-table-column>
              <el-table-column align="center" prop="userTakPhone" label="手机号"></el-table-column>
              <el-table-column align="center" prop="userTakeAddress" label="收件地址"
                               :show-overflow-tooltip="true" :min-width="150"></el-table-column>
              <el-table-column align="center" prop="recipientAddress" label="快递公司" min-width="120">
                <template slot-scope="scope">
                  <el-form-item :prop="'tableList.'+scope.$index+'.exchangeExpressName'" :rules="rules.exchangeExpressName">
                    <el-select v-model="scope.row.exchangeExpressName" placeholder="请选择快递公司">
                      <el-option
                          v-for="item in exchangeExpressName"
                          :key="item.value"
                          :label="item.label"
                          :value="item.label">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" label="快递单号" min-width="120">
                <template slot-scope="scope">
                  <el-form-item :prop="'tableList.'+scope.$index+'.exchangeExpressNumber'" :rules="rules.exchangeExpressNumber">
                    <el-input v-model="scope.row.exchangeExpressNumber" placeholder="请输入快递单号"/>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>

        </div>
      </div>
    </div>

    <div class="footer-toolbar">
      <el-button @click="$router.go(-1)">返回</el-button>
      <el-button @click="sumbit" type="primary" :loading="btnLoading">确定发货</el-button>
    </div>

    <picture-dialog :url="dialogImageUrl" :visible.sync="dialogVisible"/>

  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    const validateExchangeExpressNumber = (rule, value, callback) => {
      let reg = /^YT[0-9]{13}$/;
      if (value === '') {
        callback(new Error('请输入快递单号'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入YT开头+13位数字的单号'));
        }
        callback();
      }
    };
    const validateExchangeExpressName = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请选择快递公司'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        exchangeExpressNumber: [{required: true, validator: validateExchangeExpressNumber, trigger: 'blur'}],
        exchangeExpressName: [{required: true, validator: validateExchangeExpressName, trigger: 'blur'}],
      },
      form: {
        tableList: [] // 表格数据
      },
      upData: {},
      orderId: '',
      dialogImageUrl: '',
      tableList: [],
      tableLoading: false,
      dialogVisible: false,
      btnLoading: false,
      exchangeExpressName: [
        {label: '请选择快递公司', value: '', color: '#409EFF'},
        {label: '圆通', value: 1, color: '#409EFF'},
      ],
      evaStatus: [
        {label: '待评价', value: 1},
        {label: '已评价', value: 2}
      ],
      total: 0,
      page: 1,
      pageSize: 10,
    };
  },
  filters: {
    getOrderStatus(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getOrderStatusType(value) {
      const data = _this.orderStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].color;
      }
    },
    getEvaStatus(value) {
      const data = _this.evaStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    // this.getData();
    let _this = this;
    if (this.$route.query.data) {
      _this.form.tableList = JSON.parse(this.$route.query.data);
    }
  },
  methods: {
    // 获取待发货商品
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: _this.page,
        pageSize: _this.pageSize,
        search: {
          orderStatus: 1
        }
      }

      _this.$api
          .getOrderInfo(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.form.tableList = res.data.data.records;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    handleSee(url) {
      let _this = this;
      _this.dialogImageUrl = url;
      _this.dialogVisible = true;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    // 快递单号校验
    onExchangeChange(index) {
      let _this = this;
      let reg = /^YT[0-9]{13}$/;
      let tmpObj = this.tableList[index];
      if (tmpObj.exchangeExpressName !== '') {
        if (tmpObj.exchangeExpressNumber === '') {
          _this.$message('请输入快递单号');
          return
        } else {
          if (!reg.test(tmpObj.exchangeExpressNumber)) {
            _this.$message('请输入YT开头+13位数字的单号');
            return
          }
        }
      }
      if (tmpObj.exchangeExpressNumber !== '') {
        if (tmpObj.exchangeExpressNumber === '') {
          _this.$message('请选择快递公司');
          return
        }
      }


      // if (totalAmount > salePrice) {
      //
      //   this.tableList[index].totalAmount = salePrice;
      //   this.$set(this.curHomePageExchangeGoodsList, index, tmpObj);
      // }
    },
    inputExpressNumber() {

    },
    // 获取当前时间 格式：yyyy-MM-dd HH:MM:SS
    getCurrentTime() {
      let _this = this;
      let date = new Date();//当前时间
      let month = _this.zeroFill(date.getMonth() + 1);//月
      let day = _this.zeroFill(date.getDate());//日
      let hour = _this.zeroFill(date.getHours());//时
      let minute = _this.zeroFill(date.getMinutes());//分
      let second = _this.zeroFill(date.getSeconds());//秒

      //当前时间
      let curTime = date.getFullYear() + '-' + month + '-' + day
          + " " + hour + ':' + minute + ':' + second;

      return curTime;
    },
    // 补零
    zeroFill(i) {
      if (i >= 0 && i <= 9) {
        return '0' + i;
      } else {
        return i;
      }
    },
    // 批量发货
    sumbit() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          const shipmentsDate = _this.getCurrentTime();
          const list = [];
          _this.form.tableList.forEach(item => {
            if(item.exchangeExpressNumber && item.exchangeExpressName) {
              list.push({
                id: item.id,
                exchangeExpressName: item.exchangeExpressName,
                exchangeExpressNumber: item.exchangeExpressNumber,
                shipmentsDate: shipmentsDate,
                orderCommodityId: item.orderCommodityId,
              })
            }
          })
          _this.$api.batchTakeExchange({list:list}).then(res => {
            if (res.data.code == 200) {
              _this.btnLoading = false;
              _this.$message.success('发货成功')
              _this.$router.go(-1);
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
      return

    },
  }
};
</script>
<style scoped lang="less">
.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.content_li {
  padding: 0;
}

.content_li-padding {
  padding: 30px;
  box-sizing: border-box;
}

.content_li-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 2;
}

.el-form-item {
  width: 50%;
}

.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}

/deep/ .el-tag {
  color: #fff !important;
  border: none;
}

/deep/ .el-form-item {
  width: 100%;
}
</style>
